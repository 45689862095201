import { render, staticRenderFns } from "./cart.vue?vue&type=template&id=705ef776&scoped=true&"
import script from "./cart.vue?vue&type=script&lang=js&"
export * from "./cart.vue?vue&type=script&lang=js&"
import style0 from "./cart.vue?vue&type=style&index=0&id=705ef776&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705ef776",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartCard: require('/Users/michael.slowik/Code/controlpad/client/shop/components/cart/cart-card.vue').default})
