/**
 * Actions for Inventory module
 * imported in store/inventory.js
 */
const queryParams = 'price=retail&expands[]=product_images&expands[]=variants&expands[]=categories&expands[]=variant_images&expands[]=visibilities&available=true'
export default {
  /**********
   * READ
   * **********/

  /**
   * Action to get products
   *
   * @param {object} filters
   * @returns {promise}
   */
  async getProducts({ rootGetters }, filters) {
    const sellerTypeId = rootGetters.seller.seller_type_id
    const sid = sellerTypeId === 1 ? sellerTypeId : rootGetters.seller.id
    const categories = filters?.category ? `&categories[]=${filters.category}` : ''
    const search_term = filters?.search_term ? `&search_term=${filters.search_term}` : ''
    const per_page = filters?.per_page ? `&per_page=${filters.per_page}` : ''
    const variant = filters?.variant ? `&variant=${filters.variant}` : ''
    const sort = filters?.sort_by ? `&sort_by=${filters.sort_by}` : ''
    const page = filters?.page ? `&page=${filters.page}` : ''
    const visibilities = sellerTypeId === 1 ? 2 : 3;
    const resp = await this.$apiInventory.$get(`/products?visibilities[]=${visibilities}&user_id=${sid}&${queryParams}${categories}${search_term}${variant}${sort}${per_page}${page}`)
      .catch(e => console.log(e))
    return resp
  },

  /**
   * Action to get products by id(s)
   *
   * @param {array} ids
   * @returns {promise}
   */
  async getProductsByIds({ rootGetters }, ids) {
    const sellerTypeId = rootGetters.seller.seller_type_id
    const sid = sellerTypeId === 1 ? sellerTypeId : rootGetters.seller.id

    const idParams = []

    for (let i = 0; i < ids.length; i++) {
      idParams.push(`product_ids[]=${ids[i]}&`)
    }

    const visibilities = sellerTypeId === 1 ? 2 : 3;
    const { data } = await this.$apiInventory.$get(`/products?visibilities[]=${visibilities}&user_id=${sid}&${idParams.join('')}${queryParams}`)
      .catch(e => console.log(e))
    return data
  },

  /**
   * Action to get a product by id
   *
   * @param {number} id
   * @returns {promise}
   */
  async getProductById({ rootGetters }, id) {
    const sellerTypeId = rootGetters.seller.seller_type_id
    const sid = sellerTypeId === 1 ? sellerTypeId : rootGetters.seller.id

    return await this.$apiInventory.$get(`/products/${id}?user_id=${sid}&${queryParams}`)
      .catch(e => console.log(e))
  },

  /**
   * Action to get a product by id
   *
   * @param {string} slug
   * @returns {promise}
   */
  async getProductBySlug({ rootGetters }, slug) {
    const sellerTypeId = rootGetters.seller.seller_type_id
    const sid = sellerTypeId === 1 ? sellerTypeId : rootGetters.seller.id

    return await this.$apiInventory.$get(`/products/slug/${slug}?user_id=${sid}&${queryParams}`)
      .catch(e => console.log(e))
  },

  /**
   * Action to get the shops inventory categories
   *
   * @returns {promise}
   */
  async getCategories() {
    const resp = await this.$apiInventory.$get('/categories?expands[]=category_images&level=0')
      .catch(e => console.log(e))
    return resp.data
  },

  /**
   * Action to get the shops inventory variants
   *
   * @param {string} sort
   * @returns {promise}
   */
  async getVariants({ commit }, sort) {
    const resp = await this.$apiInventory.$get(`/variants?sort_by=${sort}`)
      .catch(e => console.log(e))

    return resp.data
  }

  /**********
   * DELETE
   * **********/
}
