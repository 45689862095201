export const AddressLookup = () => import('../../components/address-lookup.vue' /* webpackChunkName: "components/address-lookup" */).then(c => wrapFunctional(c.default || c))
export const CartlineCard = () => import('../../components/cartline-card.vue' /* webpackChunkName: "components/cartline-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutLineCard = () => import('../../components/checkout-line-card.vue' /* webpackChunkName: "components/checkout-line-card" */).then(c => wrapFunctional(c.default || c))
export const FeatureBand = () => import('../../components/feature-band.vue' /* webpackChunkName: "components/feature-band" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const LoadingBar = () => import('../../components/loading-bar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const PdfDownload = () => import('../../components/pdf-download.vue' /* webpackChunkName: "components/pdf-download" */).then(c => wrapFunctional(c.default || c))
export const ReceiptProductListItem = () => import('../../components/receipt-product-list-item.vue' /* webpackChunkName: "components/receipt-product-list-item" */).then(c => wrapFunctional(c.default || c))
export const StateSelector = () => import('../../components/state-selector.vue' /* webpackChunkName: "components/state-selector" */).then(c => wrapFunctional(c.default || c))
export const CartCard = () => import('../../components/cart/cart-card.vue' /* webpackChunkName: "components/cart-card" */).then(c => wrapFunctional(c.default || c))
export const CartExpiredModal = () => import('../../components/cart/cart-expired-modal.vue' /* webpackChunkName: "components/cart-expired-modal" */).then(c => wrapFunctional(c.default || c))
export const EventsCard = () => import('../../components/events/card.vue' /* webpackChunkName: "components/events-card" */).then(c => wrapFunctional(c.default || c))
export const EventsRow = () => import('../../components/events/row.vue' /* webpackChunkName: "components/events-row" */).then(c => wrapFunctional(c.default || c))
export const FormsAddressForm = () => import('../../components/forms/address-form.vue' /* webpackChunkName: "components/forms-address-form" */).then(c => wrapFunctional(c.default || c))
export const FormsContact = () => import('../../components/forms/contact.vue' /* webpackChunkName: "components/forms-contact" */).then(c => wrapFunctional(c.default || c))
export const FormsCreditCardForm = () => import('../../components/forms/credit-card-form.vue' /* webpackChunkName: "components/forms-credit-card-form" */).then(c => wrapFunctional(c.default || c))
export const FormsCustomerSignup = () => import('../../components/forms/customer-signup.vue' /* webpackChunkName: "components/forms-customer-signup" */).then(c => wrapFunctional(c.default || c))
export const FormsNexioPayment = () => import('../../components/forms/nexio-payment.vue' /* webpackChunkName: "components/forms-nexio-payment" */).then(c => wrapFunctional(c.default || c))
export const FormsNmiPayment = () => import('../../components/forms/nmi-payment.vue' /* webpackChunkName: "components/forms-nmi-payment" */).then(c => wrapFunctional(c.default || c))
export const FormsProductForm = () => import('../../components/forms/product-form.vue' /* webpackChunkName: "components/forms-product-form" */).then(c => wrapFunctional(c.default || c))
export const FormsShipping = () => import('../../components/forms/shipping.vue' /* webpackChunkName: "components/forms-shipping" */).then(c => wrapFunctional(c.default || c))
export const ImageLogo = () => import('../../components/image/logo.vue' /* webpackChunkName: "components/image-logo" */).then(c => wrapFunctional(c.default || c))
export const ImageOpsyImage = () => import('../../components/image/opsy-image.vue' /* webpackChunkName: "components/image-opsy-image" */).then(c => wrapFunctional(c.default || c))
export const InputsCreditCardNumber = () => import('../../components/inputs/credit-card-number.vue' /* webpackChunkName: "components/inputs-credit-card-number" */).then(c => wrapFunctional(c.default || c))
export const InputsExpiration = () => import('../../components/inputs/expiration.vue' /* webpackChunkName: "components/inputs-expiration" */).then(c => wrapFunctional(c.default || c))
export const InputsNexioPaypalButton = () => import('../../components/inputs/nexio-paypal-button.vue' /* webpackChunkName: "components/inputs-nexio-paypal-button" */).then(c => wrapFunctional(c.default || c))
export const InventoryProductCard = () => import('../../components/inventory/product-card.vue' /* webpackChunkName: "components/inventory-product-card" */).then(c => wrapFunctional(c.default || c))
export const InventoryProductImageViewer = () => import('../../components/inventory/product-image-viewer.vue' /* webpackChunkName: "components/inventory-product-image-viewer" */).then(c => wrapFunctional(c.default || c))
export const InventoryProductsGrid = () => import('../../components/inventory/products-grid.vue' /* webpackChunkName: "components/inventory-products-grid" */).then(c => wrapFunctional(c.default || c))
export const LoadersCircleLoader = () => import('../../components/loaders/circle-loader.vue' /* webpackChunkName: "components/loaders-circle-loader" */).then(c => wrapFunctional(c.default || c))
export const LoadersLineLoader = () => import('../../components/loaders/line-loader.vue' /* webpackChunkName: "components/loaders-line-loader" */).then(c => wrapFunctional(c.default || c))
export const NavigationBreadcrumbs = () => import('../../components/navigation/breadcrumbs.vue' /* webpackChunkName: "components/navigation-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ProcessAutoshipPanel = () => import('../../components/process/autoship-panel.vue' /* webpackChunkName: "components/process-autoship-panel" */).then(c => wrapFunctional(c.default || c))
export const ProcessBillingPanel = () => import('../../components/process/billing-panel.vue' /* webpackChunkName: "components/process-billing-panel" */).then(c => wrapFunctional(c.default || c))
export const ProcessCardReview = () => import('../../components/process/card-review.vue' /* webpackChunkName: "components/process-card-review" */).then(c => wrapFunctional(c.default || c))
export const ProcessContactInfoPanel = () => import('../../components/process/contact-info-panel.vue' /* webpackChunkName: "components/process-contact-info-panel" */).then(c => wrapFunctional(c.default || c))
export const ProcessPaymentPanel = () => import('../../components/process/payment-panel.vue' /* webpackChunkName: "components/process-payment-panel" */).then(c => wrapFunctional(c.default || c))
export const ProcessShippingPanel = () => import('../../components/process/shipping-panel.vue' /* webpackChunkName: "components/process-shipping-panel" */).then(c => wrapFunctional(c.default || c))
export const ProcessSummaryItems = () => import('../../components/process/summary-items.vue' /* webpackChunkName: "components/process-summary-items" */).then(c => wrapFunctional(c.default || c))
export const ProcessSummary = () => import('../../components/process/summary.vue' /* webpackChunkName: "components/process-summary" */).then(c => wrapFunctional(c.default || c))
export const FormsCoreLogin = () => import('../../components/forms/core/login.vue' /* webpackChunkName: "components/forms-core-login" */).then(c => wrapFunctional(c.default || c))
export const FormsCorePasswordReset = () => import('../../components/forms/core/password-reset.vue' /* webpackChunkName: "components/forms-core-password-reset" */).then(c => wrapFunctional(c.default || c))
export const FormsCoreRegister = () => import('../../components/forms/core/register.vue' /* webpackChunkName: "components/forms-core-register" */).then(c => wrapFunctional(c.default || c))
export const SlidersCategories = () => import('../../components/sliders/categories.vue' /* webpackChunkName: "components/sliders-categories" */).then(c => wrapFunctional(c.default || c))
export const SlidersEvents = () => import('../../components/sliders/events.vue' /* webpackChunkName: "components/sliders-events" */).then(c => wrapFunctional(c.default || c))
export const SlidersHeroImg = () => import('../../components/sliders/hero-img.vue' /* webpackChunkName: "components/sliders-hero-img" */).then(c => wrapFunctional(c.default || c))
export const SlidersHero = () => import('../../components/sliders/hero.vue' /* webpackChunkName: "components/sliders-hero" */).then(c => wrapFunctional(c.default || c))
export const SlidersProducts = () => import('../../components/sliders/products.vue' /* webpackChunkName: "components/sliders-products" */).then(c => wrapFunctional(c.default || c))
export const UiSvg = () => import('../../components/ui/404-svg.vue' /* webpackChunkName: "components/ui-svg" */).then(c => wrapFunctional(c.default || c))
export const UiAuthCard = () => import('../../components/ui/auth-card.vue' /* webpackChunkName: "components/ui-auth-card" */).then(c => wrapFunctional(c.default || c))
export const UiFooter = () => import('../../components/ui/footer.vue' /* webpackChunkName: "components/ui-footer" */).then(c => wrapFunctional(c.default || c))
export const UiLogo = () => import('../../components/ui/logo.vue' /* webpackChunkName: "components/ui-logo" */).then(c => wrapFunctional(c.default || c))
export const UiTitle = () => import('../../components/ui/title.vue' /* webpackChunkName: "components/ui-title" */).then(c => wrapFunctional(c.default || c))
export const UiDrawersCart = () => import('../../components/ui/drawers/cart.vue' /* webpackChunkName: "components/ui-drawers-cart" */).then(c => wrapFunctional(c.default || c))
export const UiMenusUser = () => import('../../components/ui/menus/user.vue' /* webpackChunkName: "components/ui-menus-user" */).then(c => wrapFunctional(c.default || c))
export const UiNavigationBreadcrumbs = () => import('../../components/ui/navigation/breadcrumbs.vue' /* webpackChunkName: "components/ui-navigation-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UiNavigationLeftMain = () => import('../../components/ui/navigation/left-main.vue' /* webpackChunkName: "components/ui-navigation-left-main" */).then(c => wrapFunctional(c.default || c))
export const UiNavigationMobile = () => import('../../components/ui/navigation/mobile.vue' /* webpackChunkName: "components/ui-navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const UiNavigationTop = () => import('../../components/ui/navigation/top.vue' /* webpackChunkName: "components/ui-navigation-top" */).then(c => wrapFunctional(c.default || c))
export const UiSelectsTerms = () => import('../../components/ui/selects/Terms.vue' /* webpackChunkName: "components/ui-selects-terms" */).then(c => wrapFunctional(c.default || c))
export const UiThemechanger = () => import('../../components/ui/themechanger/index.vue' /* webpackChunkName: "components/ui-themechanger" */).then(c => wrapFunctional(c.default || c))
export const UiLoader = () => import('../../components/ui/loader/index.vue' /* webpackChunkName: "components/ui-loader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
