import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _28b06ffd = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _c8f999c8 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7e64af10 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _ce5e0a36 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _574cfa5e = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _1aa182f8 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _258df7d7 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _41882ebf = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _5648e0a6 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _79271603 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _b577dd46 = () => interopDefault(import('../pages/checkout/process/index.vue' /* webpackChunkName: "pages/checkout/process/index" */))
const _d1040434 = () => interopDefault(import('../pages/checkout/receipt/index.vue' /* webpackChunkName: "pages/checkout/receipt/index" */))
const _5aa3a39c = () => interopDefault(import('../pages/checkout/terms.vue' /* webpackChunkName: "pages/checkout/terms" */))
const _73a07c0a = () => interopDefault(import('../pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))
const _344755b0 = () => interopDefault(import('../pages/shop/_slug/index.vue' /* webpackChunkName: "pages/shop/_slug/index" */))
const _61ad5064 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _28b06ffd,
    name: "checkout"
  }, {
    path: "/contact",
    component: _c8f999c8,
    name: "contact"
  }, {
    path: "/events",
    component: _7e64af10,
    name: "events"
  }, {
    path: "/login",
    component: _ce5e0a36,
    name: "login"
  }, {
    path: "/password-reset",
    component: _574cfa5e,
    name: "password-reset"
  }, {
    path: "/privacy",
    component: _1aa182f8,
    name: "privacy"
  }, {
    path: "/register",
    component: _258df7d7,
    name: "register"
  }, {
    path: "/returns",
    component: _41882ebf,
    name: "returns"
  }, {
    path: "/shop",
    component: _5648e0a6,
    name: "shop"
  }, {
    path: "/terms",
    component: _79271603,
    name: "terms"
  }, {
    path: "/checkout/process",
    component: _b577dd46,
    name: "checkout-process"
  }, {
    path: "/checkout/receipt",
    component: _d1040434,
    name: "checkout-receipt"
  }, {
    path: "/checkout/terms",
    component: _5aa3a39c,
    name: "checkout-terms"
  }, {
    path: "/events/:id",
    component: _73a07c0a,
    name: "events-id"
  }, {
    path: "/shop/:slug",
    component: _344755b0,
    name: "shop-slug"
  }, {
    path: "/",
    component: _61ad5064,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
