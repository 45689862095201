
import { mapGetters } from 'vuex'

export default {
  name: 'cart-expired-modal',

  props: {
    navigateTo: {
      type: String,
      default: null
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      cartLoading: 'cart/cartLoading',
      cartExpired: 'cart/expired',
      cartId: 'cart/id'
    })
  },

  methods: {
    async refreshCart() {
      if (this.cartLoading) {
        return
      }
      // @todo when expires is implemented
      // if (this.cartId) {
      //   await this.$store.dispatch('cart/emptyCart', this.cartId)
      // } else {
      await this.$store.dispatch('createCart')
      // }
      if (this.navigateTo) {
        this.$router.push(this.navigateTo)
      } else {
        location.reload()
      }
    }
  }
}
